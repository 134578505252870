export default {
    state() {
        return {
            Tags: [
                {
                    title: '平台概况',
                    // path: '/home/indexPage'
                }
                // { name: '标签一' },
                // { name: '标签二' },
                // { name: '标签三' },
                // { name: '标签四' },
                // { name: '标签五' }
            ],
            tagIndex: 0
        }
    },
    mutations: {
        // 添加tag路由
        addTagsRoute(state, data) {
            console.log('data111111111111111111111111111', data)
            if (state.Tags.findIndex(el => el.path == data.path) == -1) {
                state.Tags.push(data)
                state.tagIndex = state.Tags.length - 1;
            } else {
                state.tagIndex = state.Tags.findIndex(el => el.path == data.path)
            }
        },
        // 移出当前tag路由
        removeTagsRoute(state, data) {
            state.Tags.splice(state.Tags.indexOf(data), 1);
        },
        // 设置当前激活的tag路由
        setTagIndex(state, index) {
            state.tagIndex = index
        }
    }
}