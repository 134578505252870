<template>
  <div>
    <div class="navBtn">
      <div
        class="control-btn control-btn-small"
        v-if="this.$store.state.Collapse.isCollapse"
      >
        <i class="el-icon-d-arrow-left" @click="setIsCollapse" style="cursor:pointer"></i>
      </div>

      
      <div style="width: 100%">
        <!-- <Tags></Tags> -->
      </div>
    </div>
    <!-- 四个小盒子的外层 -->
  </div>
</template>
    <script>
// import Tags from "@/components/Tags.vue";
// import * as echarts from 'echarts';
// var option;
export default {
  data() {
    return {};
  },
  components: {
    // Tags,
  },
  methods: {
    setIsCollapse() {
      this.$store.commit(
        "changeIsCollapse",
        !this.$store.state.Collapse.isCollapse
      );
    },
    myEcharts() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            areaStyle: {},
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        // 让我们的图表调用 resize这个方法
        myChart.resize();
      });
    },
  },
  mounted() {
    // this.myEcharts()
  },
};
</script>
    <style lang="scss" scoped>
::v-deep .el-tag {
  border: none !important;
  background-color: #fafafa;
  height: 32px;
  line-height: 32px;
}

.navBtn {
  display: flex;
}

.control-btn-small {
  width: 50px !important;
}

.control-btn {
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #233040;
  width: 200px;
  height: 32px;
}
.el-icon-d-arrow-left {
  line-height: 2;
}

//
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #fff;
  box-shadow: 0px 1px 3px #999;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
//   四个盒子外层
.box1 {
  height: 100px;
  width: 98%;
  background-color: bisque;
  margin: 10px auto;
  // 上方盒子
  .title {
    text-align: left;
    font-size: 0.8rem;
    color: #6b6e7d;
    padding: 10px;
    img {
      width: 15px;
      vertical-align: middle;
    }
  }
  // 线
  .line {
    width: 100%;
    height: 1px;
    background-color: #eff1f5;
  }
  // 人
  .people {
    font-size: 0.6rem;
    line-height: 2.5;
    margin-left: 2px;
    color: #6b6e7d;
  }
  .num {
    display: flex;
    padding: 10px;
  }
}
::v-deep .el-main {
  height: 94vh !important;
  // background-color: rgb(254 250 250);
}
</style>