// 引入封装好的请求
import { getData, postData } from "./http";

let apiServe = {

  //公用
  
  // ShopSessionId接口
  // getShopSessionId: (url,data) => getData(url, 'get',data),

  get: (url,data) => getData(url, 'get',data),
  post: (url,data) => postData(url, 'post',data),
  post_excel: (url,data) => postData(url, 'post_excel',data),

  // 上传图片
  postImg: (url,data) => postData(url, 'img',data),
  getShopSessionId: (data) => getData('merchantAccount/index/getShopSessionId', 'get',data),
  // 登录基础信息
  loginShopInit: (data) => getData('merchantAccount/login/getShopInit', 'get',data),
    // 登录获取验证码
  loginVerify: (data) => getData('merchantAccount/login/getVerify', 'get',data),
   // 登录按钮
  loginSubmit: (data) => postData('merchantAccount/login/submit', 'post', data),
  // 退出登录
  loginout: (data) => getData('merchantAccount/login/loginout', 'get',data),
  //首页数据
  index: (data) => getData('adminIndex/index/index', 'get',data),

  // 菜单列表
  menu: (data) => getData('merchantSysset/menu/getMenu', 'get',data),
  //修改菜单列表
  editmenu: (data) => postData('merchantSysset/menu/edit', 'post', data),
}
export { apiServe }