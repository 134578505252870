<template>
  <div class="box" :style="{ backgroundImage: 'url(' + login_show_img + ')' }">
    <!-- <div class="box" :style="{'background':' url('+loginBg+')'}"  v-if="login_show_img=''"> -->
    <!-- <div class="box" :style="{'background':' url('+{{ login_show_img }}+')'}" v-else> -->
    <div class="main">
      <div class="box_right" style="position: relative">

        <div style="position: absolute; top: 0; right: 0; display: flex" v-if="show">
          <div style="
              color: rgb(253, 158, 4);
              font-size: 12px;
              background-color: rgba(119, 119, 119, 0.22);
              height: 10px;
              padding: 5px;
              border-radius: 8px;
              margin-top: 10px;
              cursor: pointer;
              padding-bottom: 10px;
              margin-right: 15px;
            " @click="appear">
            <div class="sanjiao"></div>
            <span v-if="codeEwm">微信扫码登录</span>
            <span v-if="codeEwm1">切换账号登录</span>

          </div>


          <img src="../assets/ewm2.png" alt="" style="width: 50px; cursor: pointer;" @click="appear" v-if="codeEwm" />
          <img src="../assets/clogin.png" alt="" style="width: 50px; cursor: pointer;" @click="appear"
            v-if="codeEwm1" />
        </div>
        <!-- 白边 -->
        <div
          style="background-color: white; width: 270px; height: 295px;  position: absolute; z-index: 2; margin-bottom: -53px;"
          v-if="codeEwm1">
          <img src="../assets/code.jpg" alt="" style="width: 241px; padding: 15px; padding-top: 24px;" />

        </div>

        <div class="right_mian">
          <div class="right_header">
            <!-- <img src="../assets/00.png" alt="" v-if="logo = ''">
                        <img :src="logo" alt=""   v-else> -->

            <template>
              <!-- <img v-if="logo" :src="logo" alt="" /> -->

              <!-- <img :src="logo" alt=""   v-else> -->
            </template>

            <h3 style="color: white; margin-right: 25px; display: flex">
              <img src="../assets/logo-03.png" alt="" style="" />
              <div style="margin-top: 9px">登录</div>
              <!-- <div style="margin-top: 9px">商户登录</div> -->
            </h3>
            <!-- <h3 style="color: white; margin-right: 25px;">{{ name }}登录</h3> -->
            <!-- <h3>车机会</h3> -->
          </div>
          <div class="right_form" :rules="rules" v-if="show">
            <div class="phone" style="border-radius: 4px">
              <div style="width: 50px; margin: auto">
                <img src="../assets/account.png" alt="" style="
                    width: 20px;
                    height: 20px;
                    color: rgb(124 124 124);
                    margin-left: 15px;
                  " />
              </div>
              <input placeholder="请输入手机号" v-model="setTimerData.username" />
            </div>

            <!-- 验证码 -->
            <div class="yan" style="border-radius: 4px">
              <!-- <img src="../../static/image/mima@2x.png" alt=""> -->
              <div style="font-size: 0.7rem; font-weight: bolder; width: 64px">
                <img src="../assets/imgcode.png" alt="" style="
                    width: 20px;
                    height: 20px;
                    color: rgb(124 124 124);
                    margin-left: 15px;
                  " />
              </div>
              <input placeholder="请输入图片验证码" name="input" v-model="setTimerData.code" />
              <button class="ycode picture" :disabled="dis">
                <div style="width: 100%; font-weight: bolder; height: 100%" @click="changeImg">
                  <img class="verifyImg" :src="verifyImg" alt="" />
                </div>
              </button>
            </div>
            <!-- 短信验证码 -->
            <div class="yan">
              <!-- <img src="../../static/image/mima@2x.png" alt=""> -->
              <div style="font-size: 0.7rem; font-weight: bolder; width: 64px">
                <img src="../assets/text-message.png" alt="" style="
                    width: 20px;
                    height: 20px;
                    color: rgb(124 124 124);
                    margin-left: 15px;
                  " />
              </div>
              <input placeholder="请输入短信验证码" name="input" v-model="loginForm.password" />
              <button class="ycode picture" :disabled="dis" @click="getCode" style="
                  color: #fd9e04;
                  font-size: 12px;
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                ">
                {{ msg }}
                <!-- 获取验证码 -->

                <!-- <div style="width: 100%; font-weight: bolder; height: 100%" @click="changeImg">
                                     获取验证码
                                </div> -->
              </button>
            </div>

            <div style="
                color: white;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                padding: 15px;
              " class="forget">
              <!-- <span @click="code" style="">记住账号</span> -->
              <el-checkbox text-color="#fff" v-model="checked">记住账号</el-checkbox>
              <span @click="code">忘记账号？</span>
              <!-- <span @click="code">商家注册</span> -->
            </div>
            <!-- 按钮 -->
            <div style="">
              <el-button class="btn" @click="submitForm()">登录</el-button>
            </div>

            <div style="color: #fd9e04; font-size: 12px; padding: 10px 10px">
              <span style="color: rgb(251 155 0 / 66%);;">还没有账号？</span>
              <span @click="register" style="cursor: pointer; color: #fd9e04;">立即注册</span>
            </div>
          </div>
          <div class="codeImg" v-if="show1">
            <h3 v-if="show1" @click="turnOff">x</h3>
            <img src="../assets/code.jpg" alt="" v-if="show1" />

          </div>
          <div style="color:white; text-align: center; margin-top: 20px;" v-if="show1">微信扫一扫，添加客服</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入封装好的api.js
import { apiServe } from "@/api/api";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      show: true,
      show1: false,
      dis: false,
      verifyImg: "",
      name: "",
      logo: "",
      login_show_img: "",
      msg: "获取验证码",

      // 扫码登录的点击显示
      codeEwm: true,
      codeEwm1: false,

      // 短信验证码
      setTimerData: {
        uniqid: "",
        code: "",
        username: "",
        appear: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          // {
          //     pattern: /[a-z]{5,10}/g,
          //     message: '5-10位字母',
          //     trigger: 'blur'
          // }
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          {
            pattern: /^\d{6}$/,
            message: "6位数字",
            trigger: "blur",
          },
        ],
      },
      check_register:'',
      // 记住账号
      checked: true
    };
  },
  methods: {
    //定义一个定时器 用来存放倒计时
    Timer() { },
    code() {
      this.show = !this.show;
      this.show1 = !this.show1;
    },
    turnOff() {
      this.show = !this.show;
      this.show1 = !this.show1;
    },

    // 扫码登录，显示整个二维码

    appear() {
      this.codeEwm = !this.codeEwm;
      this.codeEwm1 = !this.codeEwm1;
    },

    // 登录基础信息
    // loginShopInit(){
    //    let params={
    //     name:this.loginForm.name
    //    }
    // },

    getCode() {
      if(this.msg !== "获取验证码"){
        this.$message({
          message: '请稍后！',
          type: 'info'
        })
      }else {
        setTimeout(() => {
          this.setTimer();
        }, 100);
      }
    },
    setTimer() {
      apiServe
          .post("merchantAccount/login/getCodeSms", this.setTimerData)
          .then((res) => {
            if(res.error === 0){
              this.$message({
                message: '短信验证码发送成功！',
                type: 'success'
              })
              let holdTime = 60;
              this.Timer = setInterval(() => {
                if (holdTime <= 0) {
                  this.dis = false;
                  this.msg = "获取验证码";
                  clearInterval(this.Timer);
                  return;
                }
                holdTime--;
                this.msg = holdTime;
              }, 1000);
              console.log(res, "短信验证码");
            }

          });
    },
    //     submitForm() {
    //     // this.$refs[formName].validate(async (valid) => {
    //     // if (!valid) return false;
    //     // const { data: res } = await this.axios.post("login", this.loginForm)
    //     // console.log(res)
    //     // if (res.code == 101) return this.$message.error('用户名和密码错误');
    //     // if (res.code != 200) return this.$message.error('登录错误,联系管理员');
    //     // this.$message.success('登陆成功');
    //     // // 登录成功后 保存token 后面所有的接口都需要token验证
    //     // sessionStorage.token = res.data.token;
    //     // 编程式导航 登录成功 跳转到首页
    //     // this.$router.push({ path: "/home/indexPage" });
    //     // if (valid) {
    //     //     // this.$axios.post("http://.com/login",this.loginForm).then(res => {
    //     //     //     console.log(res);
    //     //     // })
    //     //     // this.axios.post("login", this.loginForm).then(res => {
    //     //     //     console.log(res);
    //     //     //     if (res.data.code == 200) {
    //     //     //         this.$message({
    //     //     //             message: "登陆成功",
    //     //     //             type: 'success'
    //     //     //         })
    //     //     //         // 登录成功后 保存token 后面所有的接口都需要token验证
    //     //     //         sessionStorage.token = res.data.data.token;
    //     //     //         // 编程式导航 登录成功 跳转到首页
    //    this.$router.push({ path: "/home" });
    //     //     //     } else {
    //     //     //         if (res.data.code == 101) {
    //     //     //             this.$message({
    //     //     //                 message: "用户名密码错误",
    //     //     //                 type: 'error'
    //     //     //             })
    //     //     //         }
    //     //     //     }
    //     //     // })

    //     // } else {
    //     //     console.log('error submit!!');
    //     //     return false;
    //     // }
    //     // });
    //     },

    // ShopSessionId接口相当于token
    async getShopSessionId() {
      // 获取前判断本地是否有缓存，如果有直接跳首页
      let old_session = localStorage.getItem("ApShop-Session-Id");
      let sign_login = localStorage.getItem("sign_login");
      if(old_session && sign_login){
        return this.$router.push("/home/index");
      }
      await apiServe.getShopSessionId().then((res) => {
        if (res.error == 0) {
          localStorage.setItem("ApShop-Session-Id", res.session_id);
          this.getLoginVerify();
          this.getloginShopInit();
        }
      });

    },
    // 调取登录接口
    async submitForm() {
      // console.log(this.loginForm, "loginForm");
      this.loginForm.username = this.setTimerData.username;
      let res = await apiServe.loginSubmit(this.loginForm);
      // console.log(res, "res数据");
      if (res.error == 0) {
        localStorage.setItem("sign_login", 1);
        // localStorage.setItem("name", res.user.username);
        //只有当选中记住密码才把账号存储起来
        if (this.checked == true) {
          localStorage.setItem("name", res.user.username);
          // console.log(111, '1111');
        }else {
          localStorage.setItem("name", '');
        }
        //当点击记住密码后,记住密码单选框变成true,此时把选中的状态传给首页
        this.$router.push("/home/index");
      }
    },

    // this.$router.push('/shouye')
    // if(res){
    //    // 存token  存userName 存role
    //   localStorage.setItem('ApShop-Session-Id',res.data.ApShop-Session-Id)s
    //   localStorage.setItem('username',res.data.username)
    //   localStorage.setItem('password',res.data.password)
    //   localStorage.setItem('uniqid',res.data.uniqid)
    //   localStorage.setItem('code',res.data.code)

    //   this.$message({
    //     message:'登录成功',
    //     type:"success"
    //   })
    //     // 跳转  首页
    //     this.$router.push('/home')
    // } else {
    //             if (res.data.code == 404) {
    //                 this.$message({
    //                     message: "用户名密码错误",
    //                     type: 'error'
    //                 })
    //             }
    //         };
    //

    //改变验证码的图片
    changeImg() {
      this.getLoginVerify();
    },
    // 登录获取验证码
    async getLoginVerify() {
      let res = await apiServe.loginVerify();
      // console.log("图片验证码", res);
      //每点击一次验证码,掉一次获取验证码的接口,然后把接口里面的uniqid赋值给setTimerData.uniqid
      this.setTimerData.uniqid = res.data.uniqid;
      this.verifyImg = res.data.img;
    },
    // 登录页基础信息
    async getloginShopInit() {
      let res = await apiServe.loginShopInit();
      this.name = res.data.name;
      this.logo = res.data.logo;
      this.check_register = res.data.check_register;
      this.login_show_img = res.data.login_show_img;
      // console.log("登录基础信息", res);
      localStorage.setItem("username", res.data.name);
    },
    // 跳转注册
    register() {
      this.$router.push({name: "Register", params: {check_register: this.check_register}});
      // this.$router.push({path: '/register', params: {check_register: this.check_register}})
    },
  },
  created() {
    // if(localStorage.getItem('sign_login') === 1){
    //     this.$router.push('/home');
    // }
    this.getShopSessionId();
    // this.getLoginVerify(), this.getloginShopInit();
  },
  mounted() {
    this.logo = " http://assets/00.png";
    if (localStorage.getItem("name")) {
      this.setTimerData.username = localStorage.getItem("name");
    }
  },
};
</script>
<style lang="scss" scoped>
.verifyImg {
  width: 100%;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.box {
  width: 100%;
  position: relative;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: right !important;
  // align-items: center;
  /* 设置背景图片的URL */
  background-size: cover;
  /* 背景图片大小为覆盖整个元素 */
  background-position: center;
  /* 背景图片居中显示 */
  background-repeat: no-repeat;

  /* 背景图片不重复 */
  .main {
    width: 360px;
    height: 430px;
    // display: flex;
    // margin: 250px;
    position: fixed;
    right: 15%;
    top: 20%;

    .box_left {
      width: 65%;
      height: 100%;
      background-color: white;
      background-image: url("../assets/login.png");
      /* 设置背景图片的URL */
      background-size: contain;
      /* 背景图片大小为覆盖整个元素 */
      background-position: center;
      /* 背景图片居中显示 */
      background-repeat: no-repeat;
    }

    .box_right {
      width: 100%;
      height: 125%;
      background-color: #535353b5;
      box-shadow: inset 0px 0px 3px 2px rgb(219 215 215 / 18%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      .right_mian {
        width: 100%;
        height: 90%;
        // background-color: aqua;
      }

      .right_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 50px 0;

        img {
          margin-right: 5px;
          height: 40px;
          // height: 25px;
          width: 125px;
          border-radius: 20px;
        }
      }

      .right_form {
        margin: 10% auto;
        // padding: 20px;
        width: 75%;

        input {
          height: 35px;
          border: none;
          outline: none;
          // padding: 5px 10px;
        }

        .phone {
          display: flex;
          background-color: rgb(83 83 83);
          border: 1px solid #535353b5;
          border-radius: 1px;
        }

        .phone>img {
          width: 20px;
          height: 20px;
          /* margin-left: 10px; */
          margin-right: 10px;
        }

        .phone>input {
          width: 80%;
          //font-size: 0.7rem;
          background-color: #535353b5;
          color: white;
        }

        .yan {
          display: flex;
          // padding: 0 10px;
          align-items: center;
          background-color: rgb(83 83 83);
          margin-top: 25px;
          border: 1px solid rgba(83, 83, 83, 0.7098039216);
          height: 35px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          input {
            width: 60%;
            //font-size: 0.7rem;
            background-color: rgba(83, 83, 83, 0.7098039216);
            height: 100%;
            color: #fff;
          }
        }

        .yan>img {
          width: 20px;
          height: 20px;
          /* margin-left: 10px; */
          margin-right: 10px;
        }

        .ycode {
          background-color: #f8f8f8;
          border: none;
          width: 25%;
          height: 45px;
          display: inline-block;
          padding: 3px;
          font-size: 0.6rem;
          // color: #257FA2;
          font-weight: 500;
        }

        .picture {
          background-color: #777777;
          height: 100%;
          width: 50%;
          padding: 0;
        }
      }
    }
  }

  // 按钮
  .btn {
    background-color: #fd9e04;
    color: #fff;
    width: 100%;
    font-size: 0.9rem;
    margin-top: 20px;
  }
}

.el-button {
  border: 1px solid #fd9e04;
}

::v-deep input::-webkit-input-placeholder {
  color: rgb(155, 153, 153) !important;
}

::v-deep input::-moz-input-placeholder {
  color: rgb(155, 153, 153) !important;
}

::v-deep input::-ms-input-placeholder {
  color: rgb(155, 153, 153) !important;
}

.forget {
  cursor: pointer;
}

.codeImg {
  position: relative;
  width: 74%;
  height: 250px;
  // background-color: aqua;
  cursor: pointer;
  text-align: center;
  background-color: white;
  margin: auto;

  img {
    width: 80%;
    // vertical-align: middle;
    height: 215px;
    margin-top: 18px;
    // position: absolute;
  }

  h3 {
    display: block;
    position: absolute;
    top: -45px;
    right: -20px;
    color: #fff;
  }
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

.el-checkbox {
  color: #fff;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #ffffff !important;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fd9e04 !important;
  border-color: #fd9e04 !important;
}

// 三角
.sanjiao {
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 7px solid rgb(119 119 119 / 22%);
  // border-l: 11px solid black;
  border-bottom: 4px solid transparent;
  border-top: 5px solid transparent;
  margin-right: 32px;
  float: right;
  margin-right: -22px;
  margin-top: 3px;
  font-size: 12px;
}
</style>