var config ={
        // api请求地址
        baseUrl:'',
        // 图片域名
        imgDomain:'',
        imgOssDomain:'https://maoting.oss-cn-qingdao.aliyuncs.com/',
        // imgOssDomain:'https://ap-shop.oss-cn-qingdao.aliyuncs.com/',
        // imgDomain:'https://test2.chejiguanjia.com/',
        // 本地端主动给服务器ping的时间，0 则不开启 ，单位秒
        // pingInterval:1500
}
export default config;