import Vue from "vue";
import Vuex from "vuex";
// 引入封装 模态框模块
import Dialog from "./modules/Dialog";
// 引入封装动态标签页
import activeRoute from "./modules/activeRoute";
// 引入 左侧侧边栏展开收起控制模块
import Collapse from "./modules/Collapse";
// 引入数据持久化插件
import createPersistedState from "vuex-persistedstate";

import tagsView from './modules/tagsView'
import consolog from "@/components/consolog";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    screenHeight: 0
  },
  mutations: {
    setScreenHeight(state, height) {
      state.screenHeight = height;
    }
  },
  actions: {
    updateScreenHeight({ commit }) {
      commit('setScreenHeight', window.innerHeight);
    }
  },
  modules: {
    Dialog,
    activeRoute,
    Collapse,
	tagsView
  },
  plugins: [
    // 配置数据持久化插件
    createPersistedState({
      // 指定本地存储的位置 默认localStorage
      storage: sessionStorage,
      // 本地存储key
      key: 'client-pc-vuex',
      // 指定存储的模块
      paths: ['Dialog', 'activeRoute', 'Collapse']
    })
  ]
});
