<template>
  <div class="home">
    <el-header style="height: 10%;">
      <HomeHeader>

      </HomeHeader>
    </el-header>
    <el-container>
      <HomeLeft></HomeLeft>
           <el-main
          style="
          background-color: #f8f6f6;
          padding: 0;
          /* height: 92vh; */
          overflow-x: hidden;
        "
      >
        <TagsView></TagsView>
<!--        <HomeMain></HomeMain>-->
             <div style="margin: 16px;background-color: #fff;border-radius: 8px;height: calc(100vh - 116px); width: calc(100% - 32px);overflow:auto;">
               <router-view style="background-color: #fff"/>
             </div>

      </el-main>
    </el-container>
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader";
import HomeLeft from "@/components/HomeLeft";
import HomeMain from "@/components/HomeMain";
import TagsView from "@/components/TagsView";
export default {
  name: "Home",
  components: {
    HomeHeader,
    HomeLeft,
    HomeMain,
    TagsView
  },
  data() {
    return {
      checked: this.$route.query.checked,
      // created() {
      //     console.log(11111, 'this.$route.query.checked');
      //     console.log(this.$route.query.checked, 'this.$route.query.checked');
      //
      // },
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // 初始设置
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$store.dispatch('updateScreenHeight');
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0 !important;
  padding: 0;
}

.home {
  height: 100%;
}

.home .el-header {
  // height: 10% !important;
  padding: 0;
  background-color: #233040;
  border-bottom: 1px solid #707070;
}

::v-deep .el-container>.el-aside {
  width: 200px !important;
}

.el-main {
  overflow: auto;
  background-color: rgb(247 247 247);
  // height: calc(100vh - 70px);
}

::v-deep html,
body {
  min-height: calc(100vh - 10px);
}

::v-deep .att_dialog {
  .el-dialog__body {
    padding: 0 10px;
  }

  .el-dialog__header {
    padding: 10px 10px 0 20px;
  }

  /* 修改 Element UI Dialog 标题样式 */
  .el-dialog__title {
    font-size: 20px;
    /* 修改字体大小 */
    font-weight: bold;
  }
}
</style>
