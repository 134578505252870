<template>
  <div class="pagination">
    <!-- 
            分页 
            size-change: pageSize(每页显示的条目个数)改变时会触发
            current-change： 当前页数currentPage改变时会触发
            current-page 当前页数
            page-sizes：每页显示个数选择器的选项配置
            page-size：每页显示的条目个数
            layout：组件布局， 子组件名用逗号隔开
            total：总共的条数
        -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
      :page-sizes="[ 10, 15, 20]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
    </el-pagination>
  </div>
</template>
<script>
export default {
  methods: {
    // 当条目个数改变的时候  需要将选中的条数传给父亲
    handleSizeChange(val) {
      this.$emit('changeRow', val)
    },
    //   当页码改变的时候  将页码传递给父亲
    handleCurrentChange(val) {
      this.$emit('changePage', val)
    }
  },
  data() {
    return {

    };
  },
  props: {
    totalCount: {
      type: Number
    },
    page: {
      type: Number
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination {
  float: right;

  .el-pagination {

    margin-top: 15px;
    margin-right: 10px;
  }
}
</style>