export default {
    state () {
        return {
            dialogUserFormVisible: false,
            userTitle: "添加"
        }
    },
    mutations: {
        // 同步修改dialogUserFormVisible 实现控制模态框显示隐藏
        userDialog(state, switchUserDialog) {
            state.dialogUserFormVisible = switchUserDialog
        },
        // 同步修改userTitle 实现控制添加编辑操作
        changeUserTitle(state, txt) {
            state.userTitle = txt
        },
    }
}