import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import configParam from '../config';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss'
Vue.use(ElementUI);

import axios from 'axios'
import VueAxios from 'vue-axios'
// 将 configParam 添加到 Vue 的原型上
Vue.prototype.$configParam = configParam;
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
