<template>
  <div class="app-container">
    <div class="header">
      <div>操作日志</div>
    </div>
    <el-row >
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <div class="input-suffix">
            <el-input
              placeholder="请输入管理员姓名"
              v-model="queryInfo.keyword"
            >
            </el-input>

            <el-date-picker
              v-model="queryInfo.start_time"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <el-date-picker
              v-model="queryInfo.end_time"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
            <!-- <div class="titles"> 审核状态：</div> -->
            <!-- <el-select v-model="tableData.status" placeholder="全部">
              <el-option :value="null" label="全部"/>
              <el-option :value="0" label="未审核"/>
              <el-option :value="1" label="审核通过"/>
              <el-option :value="2" label="审核拒绝"/>
            </el-select> -->

            <el-select v-model="queryInfo.is_login" placeholder="请选择操作类型">
              <el-option :value="null" label="全部" />
              <el-option :value="0" label="操作日志" />
              <el-option :value="1" label="登录日志" />
            </el-select>

            <el-button
              type="primary"
              style="margin-left: 10px"
              @click="getList()"
              >搜索</el-button
            >
            <el-button type="primary" @click="Reset()">重置</el-button>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      v-loading="loading"
       :max-height="tableHeight"
      style="width: 100%; "
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="username" label="操作管理员" width="220">
      </el-table-column>
      <el-table-column prop="ip" label="IP地址"> </el-table-column>
      <el-table-column prop="created_at" label="操作时间"> </el-table-column>

      <el-table-column prop="title" label="操作动作"> </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="details(scope.row)"
            >详情</el-button
          >
          <!-- <el-button type="danger" size="mini">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 使用分页 -->
    <Pagination
      :totalCount="totalCount"
      :page="queryInfo.page"
      @changeRow="goRow"
      @changePage="goPage"
    ></Pagination>
    <!-- 添加或修改对话框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="470px">
      <el-descriptions
        class="margin-top"
        :column="1"
        direction="horizontal"
        v-for="(value, key) in Details"
        :key="key"
      >
        <el-descriptions-item :label="key" labelStyle="font-weight: 600;">{{value}}</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" type="primary"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination.vue";
import { apiServe } from "@/api/api";
export default {
  data() {
    return {
      title: "创建",
      dialogVisible: false,

      queryInfo: {
        list_rows: 10,
        page: 1,
        start_time: "",
        end_time: "",
        keyword: "",
        is_login: null,
      },
      // 日志列表
      tableData: [],
      // 详情
      Details: {},

      // 分页数据
      tablePage: [],
      // 分页的数据总条数
      totalCount: 0,
      // page: {
      //   pageSizeOptions: [5, 10, 20, 50, 100]
      // },
      multipleSelection: [],
      listLoading: true,
      loading: false,
    };
  },
  computed: {
    tableHeight() {
      return this.$store.state.screenHeight ? this.$store.state.screenHeight - 300 : 580;
    },
  },
  components: {
    Pagination,
  },
  methods: {
    // 日志列表
    async getList() {
      this.loading = true;
      let res = await apiServe.get(
        "merchantSysset/log/getList",
        this.queryInfo
      );
      this.tableData = res.list.data;
      this.totalCount = res.list.total;
      this.loading = false;
      console.log(res, "日志列表");
    },

    // 详情
    async details(row) {
      this.title = "操作员日志详情";
      this.dialogVisible = true;

      let res = await apiServe.get("merchantSysset/log/getDetail", {
        id: row.id,
      });
      this.Details = res.data.primary;
      // this.tableData = res.data;
      console.log(res, "日志详情");
      // console.log(this.Details.姓名, 'Details.商城LOGO');
    },

    // 分页
    // 条数
    // getList() {
    //   listLog(this.logList)
    //     .then((response) => {
    //       this.tablePage = response.data.data.list;
    //       this.total = response.data.data.total;
    //     })
    //     .catch(() => {
    //       this.tablePage = [];
    //       this.total = 0;
    //     });
    // },

    // 分页  改变条数
    goRow(res) {
      // 分页组件传来的条数 赋值给queryInfo.rows 然后重新查询列表
      console.log(res, "条数");
      this.queryInfo.list_rows = res;
      this.getList();
      // console.log(this.queryInfo.list_rows,'this.queryInfo.list_rows');
      // this.getList();
    },

    // 分页  改变页数
    goPage(res) {
      // 分页组件传来的页数 赋值给queryInfo.page 然后重新查询列表
      this.queryInfo.page = res;
      this.getList();
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = val;
      // console.log(selection, 'selection');
      this.ids = val.map((item) => item.id);
      console.log(this.ids, "ids");
      this.multiple = !val.length;
    },

    // 重置
    Reset() {
      (this.queryInfo.keyword = ""),
        (this.queryInfo.start_time = null),
        (this.queryInfo.end_time = null),
        (this.queryInfo.is_login = 0),
        this.getList();
    },
  },
  // mounted() {
  //   // this.getList();
  //   this.getList();
  // },
  created() {
    this.getList();
    // this.getDetail()
  },
};
</script>
<style lang="scss" scoped>
.app-container{
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    padding: 20px;
}

.header {
  background-color: #fff;
  padding: 5px 17px 16px 0;
  margin: 5px 0 5px 0;
  line-height: 0;
  display: flex;
  justify-content: space-between;

  div {
    font-size: 16px;
    font-weight: 700;
    color: #515a6e;
    width: 65px;
  }
}
//  搜索栏

.bg-purple-dark {
  // background: #ffffff;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 55px;
}

::v-deep .el-input--suffix .el-input__inner,
::v-deep .el-input--prefix .el-input__inner {
  /*width: 240px;*/
  width: 100%;
}

.input-suffix {
  width: 100%;
  display: flex;
  text-align: center;
}

.el-input {
  width: 240px;
  font-size: 14px;
  margin-right: 10px;
}
// .el-col-24{
//   margin-top: 10px;
// }

::v-deep .pagination .el-pagination{
  margin-top: 0;
  padding: 9px 0;
}
</style>
