<template>
  <div class="header">
    <el-dropdown>
      <span class="el-dropdown-link">
        <!-- <img src="../assets/00.png" alt=""> -->
        <template>
          <!-- <img v-if="logo"  :src="logo" alt="" > -->
          <img v-if="shop_logo" :src="shop_logo" alt=""/>
          <span style="color: #fff; font-size: 12px">{{ shop_name }}</span>
        </template>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <a
              href="javascript:;"
              style="text-decoration: none; color: #000"
              @click="handleOut()"
          >退出登录</a
          >
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div style="color: #fff;display: flex;justify-content:center;line-height:47px">
      <div>
        <img v-if="logo" :src="logo" alt=""/>
        {{ name }}后台管理
      </div>
      <!-- {{ name }}后台管理 display: flex; padding-top: 3px;-->
      <div style="margin-left: 90px;width: 80px;text-align: center; font-size: 15px; cursor: pointer;height:46px;font-weight: 500;"
           @click="switchMenu(1)"
           :class="$store.state.Collapse.typeText === '商城' ? 'selectedModel' : ''">
<!--        <img src="../assets/storage.png" alt="" style="width: 12px;-->
<!--    height: 12px; margin-top: 2px;">-->
        <div>商城</div>
<!--        <i class="el-icon-arrow-down" style="margin-top: 3px; margin-left: 10px;"></i>-->
      </div>
      <div style="margin-left: 10px;width: 80px;text-align: center; font-size: 15px; cursor: pointer;height:46px;font-weight: 500;"
           @click="switchMenu(2)"
           :class="$store.state.Collapse.typeText === '仓储' ? 'selectedModel' : ''">
        <div>仓储</div>
      </div>
    </div>
    <!-- <div>仓储</div> -->
  </div>
</template>
<script>
import {apiServe} from "@/api/api";
import {EventBus} from "@/store/eventBus.js";

export default {
  data() {
    return {
      userName: localStorage.getItem("username"),
      name: "",
      logo: "",
      shop_name: '',
      shop_logo: '',
    };
  },
  methods: {
    // // 触发菜单栏切换
    // someMethod() {
    //   EventBus.$emit('some-event', { top: 1 });
    // },
    // 退出登录
    async handleOut() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            apiServe.loginout().then((res) => {
              if (res.error == 0) {
                console.log(res, "退出登录");
                // 清除本地缓存  绿色退出成功   跳转首页
                localStorage.removeItem("userName");
                localStorage.removeItem("sign_login");
                localStorage.removeItem("userPerms");

                this.$message({
                  type: "success",
                  message: "退出成功!",
                });

                this.$router.push("/");
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
    },

    // 登录页基础信息
    async getloginShopInit() {
      // let res = await apiServe.loginShopInit();
      // this.name = res.data.name;
      // this.logo = res.data.logo;
      // console.log("登录基础信息", res);
      // localStorage.setItem("username", res.data.name);
    },
    async getShopInfo() {
      await apiServe.get("merchantIndex/index/init").then((res) => {
        if (res.error == 0 && res?.settings) {
          this.shop_name = res?.settings.name;
          this.shop_logo = res?.settings.logo;
          localStorage.setItem("userPerms", JSON.stringify(res?.user.perms));
          this.name = res?.admin?.name ?? '';
          this.logo = res?.admin?.logo ?? '';
          localStorage.setItem("username", this.name);
          EventBus.$emit('menu-res', {});
        }
      });
    },
    switchMenu(index) {
      {
        EventBus.$emit('some-event', {top: 1,index:index});
      }

    },

  },
  created() {
    this.getloginShopInit();
    this.getShopInfo();
  },
  mounted() {
    // this.logo = "https://assets/LOGO.png";
    // this.logo =' ../assets/00.png';
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  height: 46px;

  img {
    width: 35px;
    height: 21px;
    margin-right: 5px;
    border-radius: 50%;
    vertical-align: middle;
  }
}

.el-dropdown {
  margin-right: 10px;
}

/* 滚动条滑块 */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* 滑块的颜色 */
  border-radius: 10px; /* 滑块的圆角 */
  border: 2px solid transparent; /* 滑块边框 */
  background-clip: content-box; /* 边框的裁剪方式 */
}
.selectedModel{
  color: #FD9E03;
  border-bottom: 5px #FD9E03 solid;

}
</style>
