export default {
    state () {
        return {
            isCollapse: true,
            typeText: '仓储'
        }
    },
    mutations: {
        // 同步修改isCollapse 实现控制左侧侧边栏展开收起
        changeIsCollapse(state, switchCollapse) {
            state.isCollapse = switchCollapse
        },
        changeTypeText(state, switchCollapse) {
            state.typeText = switchCollapse
        },
    }
}